@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

#introduction {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 30px;
  background-color: #faf9f9;
}


section {
  margin-bottom: 2rem;
}

ul {
  padding-left: 20px;
}

a {
  color: #333;
  text-decoration: none;
}

a:hover {
  color: #c4c4c4; 
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  will-change: opacity, transform;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: translateY(0);
}

.App {
  max-width: 100%;
  overflow-x: hidden;
}

main {
  padding: 0 2rem;
}

.intro-text-container {
  height: 10rem; /* Adjust this value based on your font sizes */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  margin-bottom: 1rem;
}

.intro-content h1 {
  font-size: 2.5rem;
  margin-bottom: 0;
  height: 2.5rem;
  line-height: 1;
}

.intro-content h2.name {
  font-size: 4rem;
  color: #A100FF;
  margin-top: 0;
  height: 4rem;
  line-height: 1;
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 0.5s ease, transform 0.5s ease;
}


.intro-content h2.name.show {
  opacity: 1;
  transform: translateY(0);
}



.intro-content h1 {
  height: 2.5rem;
  margin-bottom: 0.5rem;
}

.intro-content h2 {
  height: 4rem; 
  margin-top: 0;
}



.profile-pic-container {
  height: 0;
  position: relative;
  overflow: hidden;
  transition: height 0.5s ease-out;
}

.profile-pic-container.show {
  height: 400px; 
}

.profile-pic {
  position: relative;
  z-index: 1000;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
  margin:  0rem 0;
}

.name {
  animation: fadeInDown 0.5s ease 0.35s forwards;
}

@keyframes fadeInDown {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.typewriter {
  position: relative;
}

.typewriter.typing-complete::after {
  content: '_';
  position: absolute;
  right: -10px;
  width: 10px;
  animation: blink 0.7s step-end infinite;
}

@keyframes blink {
  from, to { opacity: 1; }
  50% { opacity: 0; }
}


.social-balls {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
}

.ball {
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
  opacity: 0;
}

.github {
  font-size: 25px;
  background-color: #333;
  animation: emergeBottomLeft 0.5s ease forwards;
}

.linkedin {
  font-size: 25px;
  background-color: #0077B5;
  animation: emergeBottom 0.5s ease forwards;
}

.resume {
  font-size: 25px;
  background-color: #676867;
  animation: emergeBottomRight 0.5s ease forwards;
}

@keyframes emergeBottomLeft {
  from {
    opacity: 0;
    transform: translate(-50%, -100%) scale(0);
  }
  to {
    opacity: 1;
    transform: translate(-250%, 75%) scale(1);
  }
}

@keyframes emergeBottom {
  from {
    opacity: 0;
    transform: translate(-50%, -100%) scale(0);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 150%) scale(1);
  }
}

@keyframes emergeBottomRight {
  from {
    opacity: 0;
    transform: translate(-50%, -100%) scale(0);
  }
  to {
    opacity: 1;
    transform: translate(150%, 75%) scale(1);
  }
}

.about-section {
  padding: 80px 0;
  background-color: #faf9f9; 
}

.about-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.about-text {
  flex: 1;
  padding-right: 40px;
}

.about-text h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.about-text p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #000000;
  margin-bottom: 15px;
}

.about-image {
  flex: 0 0 40%; 
  max-width: 400px; 
  border-radius: 10px;  
}

.about-image .slick-slider {
  width: 100%;
  height: 100%;
  
}

.about-image .slick-slide img {
  width: 100%;
  height: auto;
  border-radius: 10px;

}

.about-image .slick-dots {
  bottom: -25px;
}

.about-image .slick-prev, .about-image .slick-next {
  z-index: 1;
}

.about-image .slick-prev {
  left: 10px;
}

.about-image .slick-next {
  right: 10px;
}

@media (max-width: 768px) {
  .about-container {
    flex-direction: column-reverse; 
  }

  .about-text, .about-image {
    flex: 1 1 100%;
    padding-right: 0;
    max-width: 100%;
  }

  .about-image {
    margin-bottom: 30px;
  }
}
.skills-section {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin-top: -75px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.skills-header {
  text-align: center; 
  margin-bottom: 20px;
  position: relative; 
}

.skills-header h2 {
  display: inline-block; 
  margin: 0; 
  font-size: 1.8rem;
}

.skills-container {
  display: flex;
  justify-content: space-between;
}

.skill-category {
  flex: 1;
  margin: 0 10px;
  text-align: center;
}

.skill-category h3 {
  margin-bottom: 10px;
}

.skill-category ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skill-category li {
  margin-bottom: 5px;
  padding: 5px 10px;
  border-radius: 3px;
  display: inline-block;
}

.high-experience {
  background-color: #A100FF;
  color: white;
}

.medium-experience {
  background-color: #c054ff;
  color: white;
}

.low-experience {
  background-color: #dfa8ff;
  color: white;
}

.experience-key {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.8em;
  display: flex;
  align-items: center;
}

.experience-key span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px 0 10px;
}

@media (max-width: 768px) {
  .skills-container {
    flex-direction: column;
  }
  
  .skill-category {
    margin-bottom: 20px;
  }

  .skills-header {
    text-align: left;
  }

  .experience-key {
    position: static;
    margin-top: 10px;
  }
}

.job-content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.timeline {
  position: relative;
  padding: 20px 0;
}

.timeline::before {
  content: '';
  position: absolute;
  width: 2px;
  background-color: #333;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
}

.timeline-item {
  padding: 10px 40px;
  position: relative;
  width: 50%;
  box-sizing: border-box;
}

.timeline-item::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: white;
  border: 4px solid #A100FF;
  border-radius: 50%;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}

.left {
  left: 0;
}

.right {
  left: 50%;
}

.left::after {
  right: -17px;
}

.right::after {
  left: -17px;
}

.left::before {
  content: " ";
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  border: medium solid #ffffff;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #ffffff;
  right: 30px;
  transform: translateY(-50%);
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.1));
}

.right::before {
  content: " ";
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  border: medium solid #ffffff;
  border-width: 10px 10px 10px 0;
  border-color: transparent #ffffff transparent transparent;
  left: 30px;
  transform: translateY(-50%);
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.1));
}

.timeline-content {
  padding: 20px 20px;
  background-color: #ffffff;
  position: relative;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.timeline-date {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
}

.left .timeline-date {
  right: -200px;
}

.right .timeline-date {
  left: -200px;
}

.company-logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-right: 15px;
}
.content-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.header-text {
  flex-grow: 1;
}

.header-text h3 {
  margin: 0 0 5px 0;
}

.header-text h4 {
  margin: 0;
  color: #666;
}

.job-description {
  margin: 10px 0 0 0;
  padding-left: 20px;
}

.job-description li {
  margin-bottom: 5px;
}

.job-section {
  background-color: #faf9f9;
  padding: 2rem 0;
}

.job-content h2, .section-title{
  text-align: center;
  font-size: 2.5rem;
}
@media screen and (max-width: 600px) {
  .timeline::before {
    left: 31px;
  }
  
  .timeline-item {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }
  
  .timeline-item::before {
    left: 60px;
    border: medium solid #f0f0f0;
    border-width: 10px 10px 10px 0;
    border-color: transparent #f0f0f0 transparent transparent;
  }

  .left::after, .right::after {
    left: 15px;
  }

  .left .timeline-date, .right .timeline-date {
    right: auto;
    left: -150px;
  }
  
  .right {
    left: 0%;
  }
}

.react-activity-calendar {
  width: 100%;
  max-width: 800px;
  margin: 2rem auto;
}

.contact-section {
  padding: 4rem 2rem;
  background-image: url('../public/laguna.jpg');
  background-size: cover; 
  background-position: center; 
  width: 100vw; 
  position: relative; 
  left: 50%; 
  transform: translateX(-50%); 
}

.contact-container {
  display: flex;
  justify-content: space-between;
  max-width: 1160px;
  margin: 0 auto;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.contact-form-container {
  flex: 1;
  padding: 2rem;
}

.contact-form-container h2 {
  margin-bottom: 1.5rem;
  color: #000000;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group input,
.form-group textarea {
  width: 97%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  transition: border-color 0.3s ease;
  font-family: 'Inter', sans-serif;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #A100FF;
}

.form-group textarea {
  min-height: 150px;
  resize: vertical;
}

.submit-btn {
  padding: 0.75rem 1.5rem;
  background: linear-gradient(to right, #6f00ff, #A100FF, #6f00ff);
  background-size: 200% 100%;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-position 0.3s ease;
  font-size: 1rem;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
}

.submit-btn:hover {
  background-position: 100% 0;
}
.contact-info {
  flex: 0 0 300px;
  background: linear-gradient(to right, #A100FF, #dd00ff);
  color: white;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-info h3 {
  margin-bottom: 1.5rem;
}

.contact-info ul {
  list-style-type: none;
  padding: 0;
}

.contact-info li {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.contact-info i {
  margin-right: 0.5rem;
  font-size: 1.2rem;
}

.contact-info a {
  color: white;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.contact-info a:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }

  .contact-info {
    flex: 0 0 auto;
  }
}

.projects-section {
  padding: 2.1rem 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}



.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.project-card {
  background-color: #faf9f9;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.project-card:hover {
  transform: scale(1.05);
  color: initial;

}

.project-image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.project-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.project-card:hover .project-image {
  transform: scale(1.2);
}

.project-content {
  padding: 1rem;
}

.project-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.project-languages {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

.language-tag {
  background-color: #A100FF;
  color: #ffffff;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.project-description {
  color: #666;
  font-size: 0.875rem;
}

.project-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.project-overlay-content {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

.project-video {
  width: 100%;
  margin-bottom: 1rem;
}

.project-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.project-technologies {
  display: flex;
  flex-wrap: wrap;
}

.project-date {
  font-style: italic;
}

.project-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.project-links a {
  color: #333;
  transition: color 0.3s ease;
}

.project-links a:hover {
  color: #A100FF;
}

.copyright {
  margin-top: 2rem;
  text-align: center;
  font-size: 0.9rem;
  color: #666;
}

.github-calendar-title {
  margin-top: 3rem;
  text-align: center;
}

.coursework-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(100, 100, 100, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.coursework-content {
  background-color: rgb(255, 255, 255);
  padding: 2rem;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.coursework-content h2 {
  margin-bottom: 0rem;
  margin-top: 0rem;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 3rem;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
}

.course-item {
  display: flex;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;
}

.course-info {
  flex: 1;
  margin-right: 1rem;
}

.course-code {
  font-style: italic;
  color: #666;
}

.course-description {
  flex: 2;
}

.skills-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem; 
}

.coursework-button {
  margin-top: 1rem; 
  font-size: 0.9rem; 
  padding: 0.2rem 0.6rem; 
  background-color: white;
  color: #666;
  border: 2px solid #666;
  border-radius: 15px; 
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s, color 0.3s;
  align-self: flex-start; 
  font-family: 'Inter', sans-serif;
}

.coursework-button:hover {
  border: 2px solid #A100FF;
  background-color: #A100FF;
  color: white;
}

.scroll-indicator {
  position: fixed;
  bottom: 21px;
  right: 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
}

.indicator-point {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 4px 0;
  transition: background-color 0.3s ease;
}

.indicator-point.active {
  background-color: #A100FF;
}

.indicator-point:not(:last-child)::after {
  content: '';
  display: block;
  width: 2px;
  height: 15px;
  background-color: #ccc;
  margin: 4px auto;
}

.indicator-point.active:not(:last-child)::after {
  background-color: #A100FF;
}

@media screen and (max-width: 1260px) {
  .scroll-indicator {
    display: none;
  }
}
